




















































import { Component, Vue, Prop } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  components: {
    pacienteAnotaciones: () =>
      import("@/views/pacientes/paciente-anotaciones.vue"),
  },
})
export default class CompHeaderLabel extends Vue {
  @Prop({
    default: "label",
    required: false,
  })
  label!: string;
  @Prop({
    default: "",
    required: false,
  })
  sublabel!: string;
  public items = [{ title: "Ver anotaciones", id: 0 }];
  public showanotaciones: boolean = false;
  public id_paciente: number = 0;
  created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
  }
  public opciones(item: any) {
    switch (item.id) {
      case 0:
        this.showanotaciones = true;
        break;
    }
  }
}
